<template>
  <div>
    <!-- RU Modal -->
    <b-modal
      id="ru_modal"
      :title="`Update Judge: ${ru_modal.judge.name}`"
      size="sm"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <div>
        <b-row>
          <b-col>
            <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
              <b-form role="form" @submit.prevent="handleSubmit(submit_form)">
                <input type="submit" ref="ru_modal_form_submit_button" hidden />
                <b-row>
                  <b-col>
                    <h4>Active</h4>
                    <base-switch
                      v-model="ru_modal.form.active"
                      class="ml-1 mr-1"
                      on-text="Yes"
                      off-text="No"
                    ></base-switch>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-right">
            <base-button
              type="danger"
              :pill="true"
              class="my-4 btn-success"
              @click="$bvModal.hide('ru_modal')"
              :disabled="ru_modal.loading"
              >Close
            </base-button>
            <base-button
              type="success"
              :pill="true"
              class="my-4 btn-success"
              @click="ru_modal_save()"
              :disabled="ru_modal.loading"
              :loading="ru_modal.loading"
              :success="ru_modal.success"
              >Save
            </base-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <!-- C Modal -->
    <b-modal
      id="c_modal"
      title="Invite Judge"
      size="md"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <div>
        <b-row>
          <b-col>
            <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
              <b-form
                role="form"
                @submit.prevent="handleSubmit(c_modal_invite_judge)"
              >
                <input type="submit" ref="c_modal_form_submit_button" hidden />
                <b-row>
                  <b-col>
                    <base-input
                      :pill="true"
                      label="Email Address"
                      class="mb-3"
                      name="Email Address"
                      :rules="{
                        required: true,
                        email: true,
                      }"
                      placeholder="Email of the judge to be invited"
                      v-model="c_modal.form.email"
                      :disabled="c_modal.loading"
                    >
                    </base-input>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-right">
            <base-button
              type="danger"
              :pill="true"
              class="my-4 btn-success"
              @click="$bvModal.hide('c_modal')"
              :disabled="c_modal.loading"
              >Close
            </base-button>
            <base-button
              type="success"
              :pill="true"
              class="my-4 btn-success"
              @click="$refs.c_modal_form_submit_button.click()"
              :disabled="c_modal.loading"
              :loading="c_modal.loading"
              :success="c_modal.success"
              >Invite
            </base-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col cols="6">
            <h1 class="mb-0">Judges</h1>
          </b-col>
          <b-col cols="6" class="text-right">
            <el-tooltip
              content="Add"
              placement="top"
              v-if="is_hackathon_manager || is_hackathon_creator"
            >
              <base-button
                type="primary"
                round
                icon
                size="sm"
                @click="$bvModal.show('c_modal')"
              >
                <span class="btn-inner"><i class="fa fa-plus"></i></span>
              </base-button>
            </el-tooltip>
          </b-col>
        </b-row>
        <div v-if="$apollo.loading && table_data.length == 0">
          <b-row class="mt-2 d-flex align-items-center justify-content-center">
            <b-col class="d-flex align-items-center justify-content-center">
              <Transition>
                <h1>
                  <i class="fas fa-spinner fa-spin ml-1"></i>
                </h1>
              </Transition>
            </b-col>
          </b-row>
        </div>
        <b-row v-else>
          <b-col>
            <el-table
              class="table-responsive align-items-center table-flush no-lines"
              header-row-class-name="thead-light"
              row-class-name="table-rows"
              :data="table_data"
              :empty-text="`No judges yet${
                is_hackathon_manager || is_hackathon_creator
                  ? ', use the \'+\' button to invite judges.'
                  : '...'
              }`"
            >
              <el-table-column label="Name" min-width="240px" sortable>
                <template v-slot="{ row }">
                  <p class="mb-0">{{ row.name }}</p>
                </template>
              </el-table-column>
              <el-table-column label="" min-width="240px">
                <template v-slot="{ row }">
                  <a
                    v-if="row.linkedin_url"
                    :href="row.linkedin_url"
                    target="_blank"
                    >Linked In</a
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="Status"
                min-width="240px"
                sortable
                v-if="is_hackathon_manager || is_hackathon_creator"
              >
                <template v-slot="{ row }">
                  <p class="mb-0" v-if="row.status == 'ACTIVE'">Active</p>
                  <p class="mb-0" v-if="row.status == 'DISABLED'">Inactive</p>
                </template>
              </el-table-column>
              <el-table-column
                min-width="180px"
                align="center"
                v-if="is_hackathon_manager || is_hackathon_creator"
              >
                <div slot-scope="{ row }" class="table-actions">
                  <el-tooltip content="Edit" placement="top">
                    <a
                      href="#!"
                      @click.prevent="ru_modal_show(row)"
                      class="table-action"
                      data-toggle="tooltip"
                      data-original-title="Update Judge"
                    >
                      <i class="fas fa-edit"></i>
                    </a>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
// Modules
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
} from "element-ui";

// Queries
import { GET_HACKATHON_JUDGES } from "@/graphql/queries";

// Mutations
import {
  UPDATE_HACKATHON_JUDGING_JUDGE,
  CREATE_HACKATHON_JUDGING_INVITE,
} from "@/graphql/mutations";

export default {
  name: "HackthonCrudJudgesTable",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  props: {
    hackathon_id: {
      type: String,
      description: "The Id of the hackathon being viewed.",
      default: null,
    },
  },
  apollo: {
    get_hackathon_judges: {
      query: GET_HACKATHON_JUDGES,
      result(res) {
        let flattened = graph_utils.flattened_response(res.data);

        this.is_hackathon_creator = res.data.hackathonHackathon.isCreator;
        this.is_hackathon_manager = res.data.hackathonHackathon.isManager;
        this.table_data = [];
        flattened.allHackathonJudgingJudge.forEach((el) => {
          let judge_data = {
            id: el.id,
            name: `${el.profile__user__firstName} ${el.profile__user__lastName}`,
            linkedin_url: el.profile__linkedinUrl,
            status: el.status,
          };
          if (this.is_hackathon_manager || this.is_hackathon_creator) {
            this.table_data.push(judge_data);
          } else {
            if (judge_data.status !== "DISABLED") {
              this.table_data.push(judge_data);
            }
          }
        });
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      variables() {
        return {
          hackathon_id: this.hackathon_id,
        };
      },
      update(data) {
        this.apollo_data.get_hackathon_judges = data;
      },
      skip: true,
    },
  },
  data() {
    return {
      apollo_data: {
        get_hackathon_judges: null,
      },
      table_data: [], // All the judges table data is in here.
      ru_modal: {
        loading: false,
        success: false,
        form: {
          active: false,
        },
        judge: {
          id: null,
          name: null,
        },
      },
      c_modal: {
        loading: false,
        success: false,
        form: {
          email: null,
        },
      },
      is_hackathon_manager: false,
      is_hackathon_creator: false,
    };
  },
  methods: {
    // Modals

    // ru_modal
    ru_modal_show(judge) {
      if (this.is_hackathon_manager) {
        this.ru_modal.form.active = judge.status == "ACTIVE";
        this.ru_modal.judge = judge;
        this.$bvModal.show("ru_modal");
      }
    },
    ru_modal_save() {
      if (this.ru_modal.judge.id) {
        this.ru_modal.loading = true;

        this.$apollo
          .mutate({
            mutation: UPDATE_HACKATHON_JUDGING_JUDGE,
            variables: {
              judge_id: this.ru_modal.judge.id,
              status: this.ru_modal.form.active ? "ACTIVE" : "DISABLED",
            },
          })
          .then(() => {
            this.$apollo.queries.get_hackathon_judges.refetch();

            this.ru_modal.loading = false;

            this.ru_modal.success = true;
            setTimeout(() => {
              this.ru_modal.success = false;
            }, 1000);
            setTimeout(() => {
              this.$bvModal.hide("ru_modal");
            }, 800);
          })
          .catch((err) => {
            console.log(err);
            this.ru_modal.loading = false;
          });
      }
    },

    // c_modal
    c_modal_invite_judge() {
      this.c_modal.loading = true;
      this.$apollo
        .mutate({
          mutation: CREATE_HACKATHON_JUDGING_INVITE,
          variables: {
            hackathon_id: this.hackathon_id,
            email: this.c_modal.form.email,
          },
        })
        .then(() => {
          this.c_modal.loading = false;

          this.c_modal.success = true;
          setTimeout(() => {
            this.c_modal.success = false;
          }, 1000);
          setTimeout(() => {
            this.$bvModal.hide("c_modal");
          }, 800);
          this.$notify({
            message: `Invite successfully sent to: '${this.c_modal.form.email}'`,
            timeout: 5000,
            icon: "ni ni-check-bold",
            type: "success",
          });
        })
        .catch((err) => {
          if (String(err).includes("ID cannot represent value: None")) {
            this.c_modal.success = true;
            setTimeout(() => {
              this.c_modal.success = false;
            }, 1000);
            setTimeout(() => {
              this.$bvModal.hide("c_modal");
            }, 800);
            this.$notify({
              message: `Invite successfully sent to: '${this.c_modal.form.email}'`,
              timeout: 5000,
              icon: "ni ni-check-bold",
              type: "success",
            });
          } else {
            console.log(err);
          }

          this.c_modal.loading = false;
        });
    },
  },
  watch: {
    hackathon_id() {
      if (this.hackathon_id) {
        graph_utils.enable_query(this.$apollo.queries.get_hackathon_judges);
      } else {
        graph_utils.disable_query(this.$apollo.queries.get_hackathon_judges);
      }
    },
  },
  mounted() {
    if (!this.$apollo.queries.get_hackathon_judges.skip) {
      this.$apollo.queries.get_hackathon_judges.refetch();
    }
  },
  created() {
    // setting this queries fetch policy
    graph_utils.set_fetch_cache_and_network(
      this.$apollo.queries.get_hackathon_judges
    );
  },
};
</script>

<style></style>
